import {
	MicrosoftClientID,
	MicrosoftAuthority,
	MicrosoftRedirectUri,
	MicrosoftLogoutUri,
} from "../environmentVariables";

const msalConfig = {
	auth: {
		clientId: MicrosoftClientID,
		authority: MicrosoftAuthority,
		redirectUri: MicrosoftRedirectUri,
		postLogoutRedirectUri: MicrosoftLogoutUri,
	},
	cache: {
		cacheLocation: "sessionStorage",
		storeAuthStateInCookie: false,
	},
};

const loginRequest = {
	//scopes: ['https://org768355c1.api.crm4.dynamics.com/.default']
	scopes: ["https://ainos.crm4.dynamics.com/.default"],
};

const graphConfig = {
	graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

export { msalConfig, loginRequest, graphConfig };
